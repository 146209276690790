#wrapper.toggled {
    @media only screen and ( max-width: 1629px ) {
        .scrollTop   {
            right: 20px;
        }
    }
}

#wrapper {
    .scrollTop {
        @media only screen and ( max-width: 1386px) {
            right: 20px;
        }
    }
}