.ant-modal-mask {
	background-color: rgba(36, 42, 56, 0.7)
}

.ant-modal-wrap {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;

	.ant-modal {
		transform-origin: 0 !important;
		top: auto;
	}
}

.delete-all-tags-modal {
	width: 100% !important;
	max-width: 560px;

	.ant-modal-body {
		padding: 63px 84px 40px;
	}
}

.tagging-for-proxy-modal {
	width: 100% !important;
	max-width: 560px;

	.ant-modal-body {
		padding: 41px 30px;
	}
}

.userVideosPublicationModal {
	padding: 32px $smallSpacing 40px; 

	.confirmPublishModalbuttonWrapper {
		display: flex;
		margin-top: 30px;
		button {
			width: 150px;
		}
	}
}