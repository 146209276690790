.standart-text {
	font-size: 12px;
	line-height: 14px;
	font-weight: 400;
}

.bold-text {
	font-size: 12px;
	line-height: 36px;
	font-weight: 700;
}
