.ant-checkbox-wrapper {
	display: flex;
	align-items: center;
	font-size: 12px;
}

.ant-checkbox {
	top: unset;
}

.ant-checkbox-inner {
	width: 20px;
	height: 20px;
	background-color: #fff;
	border: 2px solid $mainBlue;
	border-radius: 4px;
}
.ant-checkbox:hover {
	border-radius: 4px;
}

.ant-checkbox-checked .ant-checkbox-inner {
	background-color: $mainBlue;
	border-color: $mainBlue;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
	border-color: $mainBlue;
}

.ant-checkbox + span {
	font-size: 12px;
	line-height: 21px;
}

.ant-radio-disabled + span {
	color: $mainFontColor;
}
.ant-radio {
	.ant-radio-inner:after {
		top: 5px;
		left: 5px;
		display: block;
		width: 7px;
		height: 7px;
		transform: scale(1);
		margin:0
	}
}

.ant-radio-disabled .ant-radio-inner {
	opacity: 0.4;
	border-color: $mainBlue!important;
}