textarea.ant-input {
	padding: 13px 12px 10px;
	font-size: 12px;
	line-height: 16px;
	transition: unset;
	&:focus {
		padding: 12px 11px 9px;
	}
	&.scene-tag {
		height: 100%;
	}
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
	padding-bottom: 5px;
	padding-left: 8px;
}

.ant-form-item-label {
	line-height: 12px;

	& > label {
		font-weight: bold;
		font-size: 12px;
	}
}


.ant-form-item-explain.ant-form-item-explain-error {
	min-height: auto;
	div {
		line-height: normal;
	}
}

.textAreaWrapper {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.ant-form-item-control-input-content {
	height: 100%;
}

textarea.ant-input.scene-tag.secondaryFocus:focus {
	border: solid 2px #38C9BA!important;
	box-shadow: none;
}