.header {
	background: #EBEBEB;
	height: 67px;
	display: flex;
	flex-direction: row;
}

.page-info {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex: 1;
	padding: 0 24px 0 39px;
	margin: 13px 0 14px 0;
	border-right: 2px solid $mainGray;
}

.back {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-weight: bold;
	font-size: 12px;
	line-height: 18px;
	text-transform: capitalize;
	color: $mainBlue;


	img {
		margin-right: 14px;
	}
}
.back, .page-info {
    display: flex;
    align-items: center
}


.user-menu {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	width: 230px;
	padding: 0 30px 0 15px;
}

.user-info {
	text-align: right;
	p {
		font-size: 12px;
		line-height: 14px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		max-width: 118px;
	}
}

.avatar {
	margin: 0 $smallSpacing;
}