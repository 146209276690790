.noUi-target {
	background: $mainGray;
	border-radius: 0;
	border: none;
	box-shadow: none;
}

.noUi-connects .noUi-connect.empty {
	background-color: #d1d1d1;
}

.noUi-connects .noUi-connect.empty.active {
	background-color: #D4A8E8;
}

.noUi-connects .noUi-connect.scene {
	background-color: #979797;
}

.noUi-connects .noUi-connect.active {
	background-color: #38c9ba;
}


.noUi-horizontal{
	height: 16px;
}

.noUi-handle {
	cursor: grab!important;
}

.noUi-handle:active {
	cursor: grabbing!important;
}


.noUi-horizontal .noUi-handle {
    width: 2px;
    height: 18px;
    right: -1px;
    top: -1px;
	border-radius: 1px;
}

.noUi-horizontal .noUi-handle.split-marker {
    width: 2px;
    height: 33px;
    right: -1px;
    top: -12px;
}

.noUi-handle:before, .noUi-handle:after {
	display: none;
}

.noUi-connects {
	border-radius: 0;
}

.noUi-horizontal .noUi-tooltip {
	left: 50%;
}


.split-marker {
	transform: rotate(180deg) translateY(5px);
	 .noUi-tooltip {
		transform: rotate(180deg) translate(50%);
	    box-shadow: 0 0 3px 0.4px #fff;
		 &:after {
			top: 12px;
			transform:  rotate(45deg);
			left: 18px;

		 }
		 &:before {
			top: 12px;
			transform: rotate(-45deg);
		 }
	 }
	&.focused {
		  .noUi-tooltip {
			  &:after {
				  top: 16px;
			  }
			  &:before {
				  top: 16px;
			  }
		  }
	 }
}

.hide-markers {
	display: none;
}

.noUi-handle {
	// background-image: url("data:image/svg+xml,%3Csvg width='1' height='26' viewBox='0 0 1 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='1' height='26' rx='0.5' fill='%23EB5757'/%3E%3C/svg%3E");
	border: none;
	width: 2px;
	border-radius: 0;
	background-color: transparent;
	cursor: default;
	box-shadow: unset;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 3px;
	background-color: #EB5757;
}

.cursor .noUi-handle {
    top: -3px;
	width: 2px;
    right: -1px;
    height: 20px;
	background: $mainFontColor;
	box-shadow: 0px 0px 4px #FFFFFF;
	border-radius: 1px;
}

.cursor .noUi-handle.focused {
	top: -5px;
	height: 22px;
}

.noUi-origin[disabled=true] {
	display: none;
}

.noUi-connects {
	overflow: inherit;
}

//Tooltip
.noUi-horizontal .noUi-tooltip {
	bottom: unset;
	margin-top: 2px;
}

.noUi-tooltip {
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 10px;
	color: #FFFFFF;
	background: #EB5757;
	border: none;
	border-radius: 3px;
	padding: 1px 2px 2px;
	// box-shadow: 0 0 1px 1px #fff;
	
	&:after {
		content: '';
		position: absolute;
		top:-2px;
		left: 18px;
		transform: rotate(135deg);
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 3px 3px 3px;
		border-color: transparent transparent #EB5757 transparent;
	}
	&:before {
		content: '';
		position: absolute;
		top:-2px;
		left: 19px;
		transform: rotate(-135deg);
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 3px 3px 3px;
		border-color: transparent transparent #EB5757 transparent;
	}
	
}

.noUi-handle.focused {
	.noUi-tooltip {
		font-size: 11px;
		line-height: 11px;
		padding: 3px 2px 3px; 
		&:after {
			left: 20px;
		}
		&:before {
			left: 21px;
		}
	}
}

//Tooltip cursor
.cursor {
	.noUi-tooltip {
		top: -16px;
		// margin-top: 0;
		margin-bottom: 2px;

		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		line-height: 10px;
		color: #FFFFFF;
		background: $mainFontColor;
		border-radius: 3px;
		border: none;
		padding: 1px 2px 2px;
		box-shadow: 0 0 3px 0.4px #fff;
		&:after {
			content: '';
			position: absolute;
			top: unset;
			bottom:-2px;
			left: 19px;
			transform: rotate(135deg);
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 3px 3px 0 3px;
			border-color: $mainFontColor transparent transparent transparent;
		}
		&:before {
			content: '';
			position: absolute;
			top: unset;
			bottom:-2px;
			left: 18px;
			transform: rotate(-135deg);
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 3px 3px 0 3px;
			border-color: $mainFontColor transparent transparent transparent;
		}
	}
}

.cursor .noUi-handle.focused {
	.noUi-tooltip {
		font-size: 11px;
		line-height: 11px;
		padding: 3px 2px 3px;

		&:after {
			left: 21px;
		}

		&:before {
			left: 20px;
		}
	}
}

//Pips
.noUi-pips-horizontal {
	height: 25px;
	padding: 1px 0 8px;
	font-style: normal;
	font-weight: normal;
	font-size: 8px;
	line-height: 9px;
	text-align: center;
	color: #979797;
}

.noUi-marker-horizontal.noUi-marker {
	margin-left: 0;
	width: 1px;
	height: 2px;
}

.noUi-marker-horizontal.noUi-marker-large {
	height: 4px;
}

//Cursor slider
.cursor {
	z-index: 2;
	&.noUi-horizontal{
		height: 0;
	}
}

video::-webkit-media-controls {
	display:none !important;
}
video::-webkit-media-controls-enclosure {
	display:none !important;
}

.noUi-horizontal { 
	.noUi-origin.tagDividerMarker {
		.noUi-handle {
			background-image: url("data:image/svg+xml,%3Csvg width='1' height='26' viewBox='0 0 1 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='1' height='26' rx='0.5' fill='%23242A38'/%3E%3C/svg%3E");	
			top: 0;
			width: 2px;
			right: -2px;
			height: 16px;
			cursor: default !important ;
			.noUi-tooltip {
				display: none;
			}
		}
	}
}

/*
Fullscreen Pseudo-class:
https://developer.mozilla.org/en-US/docs/Web/CSS/:fullscreen
*/
.vidBar:-moz-full-screen { position: fixed; }
.vidBar:-webkit-full-screen { position: fixed; }
.vidBar:-ms-fullscreen { position: fixed; }
.vidBar:fullscreen { position: fixed; }