.ant-select .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
  font-size: 12px;
  transition: none;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
  font-size: 12px;
  color: $mainGray;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  color: $mainFontColor;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
  border: 1px solid $mainGray;
  background: #FFFFFF;
  border-radius: 4px;
}

.ant-select.ant-select-single.ant-select-allow-clear.ant-select-show-arrow.ant-select-disabled.ant-select-show-search .ant-select-selector {
  border-color: $grayLight;
}

// .ant-select.green:not(.ant-select-customize-input) .ant-select-selector {
// 	border-color: #38C9BA;
// }

.ant-select-clear svg {
  fill: $mainBlue;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  padding-top: 10px;
}

.withPlaceholder {

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: auto;
    padding-top: 14px;
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: unset;
    padding-top: 14px;
  }
}

.ant-form-item {
  margin-bottom: 0;

  &.mbSmall {
    margin-bottom: $smallSpacing
  }
}

.has-tooltip .ant-form-item-label {
  overflow: unset;

  label {
    position: absolute;
    top: -13px;
    left: -11px;
    width: 12px;
    height: 12px;

    &:hover {
      cursor: pointer;
    }
  }

  label::after {
    display: none;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $inputGray;
  transition: none;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {

  .ant-select-selection-placeholder {
    color: $inputGray;
  }
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  color: $mainFontColor;
  transition: none;
}

.rc-virtual-list-scrollbar {
  background-color: $mainGray;
  opacity: 0.3;
  border-radius: 6px;
  width: 6px !important;
}

.rc-virtual-list-scrollbar-thumb {
  width: 6px;
}

.ant-select.ant-select-single.ant-select-allow-clear.ant-select-show-arrow.ant-select-disabled.ant-select-show-search .ant-select-selector {
  border-color: $inputGray;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: $grayLight;
}

.ant-select-dropdown.event-dropdown {
  padding-right: 2px;
}

.ant-select-dropdown {
  border-radius: 8px;
}

.rc-virtual-list-holder {
  &::-webkit-scrollbar {
    border-radius: 8px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollThumbNailGray;
    border-radius: 10px;
    margin-right: 10px;
  }
}