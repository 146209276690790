.placeholder-main {
	font-size: 12px;
	line-height: 16px;
	font-weight: 400;
	color: $inputGray;
}

.placeholder-small {
	font-size: 8px;
	line-height: 8px;
	font-weight: 400;
	color: $inputGray;
}
