.ant-picker {
    line-height: 38px;
    font-size: 12px;
    height: 40px;
    border: 1px solid $mainGray;
    background:  #FFFFFF;
    border-radius: 4px;
    
    .ant-picker-suffix {
        display: none;
    }

 
}

//    .ant-picker-footer {
//         display: none;
//     }