.ant-tabs {
    overflow: unset;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    padding: 10px 26px;
}

.ant-tabs.ant-tabs-top.ant-tabs-middle.ant-tabs-card {

    .ant-tabs-nav {
        margin-bottom: 0;
    }
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 0;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border: none;
    background: transparent;
    font-weight: bold;
    font-size: 12px;
    color: $tabTitleGray;
    text-transform: capitalize;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px 5px 0px 0px;

    .ant-tabs-tab-btn   {
       color: $mainFontColor;
    }
}

.ant-tabs-content-holder {
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    background: #ffffff;
    font-size: 12px;
    border-radius: 0px 0px 5px 5px;
    padding: $mainSpacing;
    min-height: 412px;
}

.ant-tabs-tabpane {
    min-height: 100%;
}

.ant-tabs-content.ant-tabs-content-top {
    min-height: 100%;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab.widthCompensator {
    padding: 10px 29px;
}