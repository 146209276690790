html {
  // overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  overflow: auto;
  margin-right: 10px;
  background-color: $mainBackgroundColor;
}

#wrapper {
  overflow: auto;
  font-family: $mainFont;
  font-size: $mainFontSize;
  font-weight: $mainFontWeight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a {
    color: $mainBlue;

    &:hover {
      color: $mainBlue;
    }
  }

  h1 {
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    text-transform: capitalize;
  }
}

.relative {
  position: relative;
}

#root,
#FanTagsRoot {
  @include flex-1;

  flex: auto;
  // padding: $mainSpacing ;
  width: 100%;
  // height: calc(100vh - 67px);
  min-height: calc(100vh - 67px);
}

.fit-content {
  width: fit-content;
}

.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.flex-1 {
  flex: 1
}

.flex-column {
  flex-direction: column;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.ant-divider-horizontal {
  margin: 0;
  border-top: solid 1px $mainGray;
}

.mbone {
  margin-bottom: 0;
}

.mb5 {
  margin-bottom: 5px;
}

.mbMain {
  margin-bottom: $mainSpacing;
}

.mbMain {
  margin-bottom: $mainSpacing;
}

.mbSmall {
  margin-bottom: $smallSpacing;
}

.mlMain {
  margin-left: $mainSpacing;
}

.mlExtraLarge {
  margin-left: $extraLargeSpacing;
}

.mrMain {
  margin-right: $mainSpacing;
  ;
}

.mtMain {
  margin-top: $mainSpacing;
}

.mtLarge {
  margin-top: $largeSpacing
}

.mtSmall {
  margin-top: $smallSpacing;
}

.mtExtraLarge {
  margin-top: $extraLargeSpacing;
}

.mrExtraLarge {
  margin-right: $extraLargeSpacing;
}

.mbExtraLarge {
  margin-bottom: $extraLargeSpacing;
}

.mbLarge {
  margin-bottom: $largeSpacing;
}

.mbMain {
  margin-bottom: $mainSpacing;
}

.mbSmall {
  margin-bottom: $smallSpacing;
}

.mrSmall {
  margin-right: $smallSpacing;
}

.mlSmall {
  margin-left: $smallSpacing;
}

.mtNone {
  margin-top: 0;
}

.self-align-center {
  align-self: center;
}

.mbNone {
  margin-bottom: 0;
}

.pbNone {
  padding-bottom: 0;
}

div {
  &.pbNone {
    padding-bottom: 0;
  }
}

.d-none {
  display: none;
}

.maxWidthPrimary {
  width: 100%;
  max-width: 1174px;
}

.mainPage {
  color: $mainFontColor;
  justify-content: center;
  max-width: $mainPagetWidth;
  display: flex;
  width: 100%;
}

.smallContainer {
  width: 100%;
  max-width: 670px;
}