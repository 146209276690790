.ant-radio-wrapper {
	line-height: 33px;
	display: flex;
	align-items: center;
}

.ant-radio {
	top: unset;
}

.ant-radio-inner {
	width: 21px;
	height: 21px;
	background-color: #fff;
	border: 2px solid $mainBlue;
}

.ant-radio-checked {
	.ant-radio-inner, .ant-radio-inner:active, .ant-radio-inner:focus {
		background-color: $mainBlue;
		border: 2px solid $mainBlue;

		&:after {
			// top: 5px;
			// left: 5px;
			// width: 7px;
			// height: 7px;
			background-color: #fff;
		}
	}
}

span.ant-radio + * {
	font-size: 12px;
	line-height: 21px;
}

#wrapper.toggled {

	@media(max-width:1735px) {
		#filterCheckBox {
			flex-direction: column;
			align-items: flex-start;

			.timeCheckbox {
            	margin-right: 0;
	        }
		}
	}

	@media(max-width:1621px)
	{
		 .fanTagsradioButtons {
            flex-wrap: wrap;
            justify-content: flex-start;
       }
	}

	#fanTagsFiltersSpan {
		margin-right: 43px;
		line-height: unset;
	}
}
#filterCheckBox {
	@media(max-width:1562px)
	{
		flex-direction: column;
		align-items: flex-start;;
	}

	@media(max-width: 1449px) {
		.fanTagsradioButtons {
			flex-wrap: wrap;
				justify-content: flex-start;
		}
	}
}

.ant-radio-wrapper {
	line-height: 1;
}