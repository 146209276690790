.ant-table {
    line-height: unset;
    background: unset;
}

.ant-table-container {
    .ant-table-content {
        table {
            color: $mainFontColor;

            tr {
                font-size: 12px;
            }

            a {
                font-weight: bold;
            }

            table-layout: fixed;
            white-space: pre-line;
            background: transparent;
            width: 100%;

            .ant-table-thead {
                background: $grayLight;
                border-radius: 8px 8px 0px 0px;

                & tr {
                    & th {
                        background: $grayLight;
                        font-size: 12px;
                        font-weight: 700;
                        letter-spacing: 0.01em;
                        text-transform: capitalize;
                        padding-top: $mainSpacing;
                        padding-bottom: $mainSpacing;
                        color: $mainFontColor;

                        &::before {
                            display: none;
                        }
                    }
                }

                & tr:first-child th {
                    &:first-child {
                        border-top-left-radius: 8px;
                    }
                }

                & tr:last-child th {
                    &:last-child {
                        border-top-right-radius: 8px;
                    }
                }
            }

            .ant-table-tbody {
                background: #fff;

                tr {
                    vertical-align: inherit;
                }

                .ant-table-cell {
                    padding-top: $mainSpacing;
                    padding-bottom: $mainSpacing;
                    position: relative;
                }
            }
        }
    }
}

// ----------- All columns +  Invisible columns -----------
.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
    // width: 6.69%;
    max-height: 127px;
    padding-left: 0;
    padding-right: 0;
    vertical-align: middle;
    border-bottom: 2px solid $mainBackgroundColor;
}

// ----------- Primary columns th -----------
.ant-table-thead>tr>th.withoutPadding,
.ant-table tfoot>tr>th.withoutPadding {
    padding: $mainSpacing 0;
    width: max-content;
    display: block;
}

// ----------- Primary columns td -----------
.ant-table-tbody>tr>td.withoutPadding,
.ant-table tfoot>tr>td.withoutPadding {
    padding: $mainSpacing 0;
    width: fit-content;
}

// ----------- First Column -----------
.ant-table-thead>tr>th.firstColumn,
.ant-table tfoot>tr>th.firstColumn {
    padding-right: 0px;
    padding-left: $mainSpacing;
    width: max-content;
}

.ant-table-tbody>tr>td.firstColumn,
.ant-table tfoot>tr>td.firstColumn {
    padding-right: 0px;
    padding-left: $mainSpacing;
}

// ----------- Last Column -----------
.ant-table-thead>tr>th.lastColumn,
.ant-table-tbody>tr>td.lastColumn,
.ant-table tfoot>tr>th.lastColumn,
.ant-table tfoot>tr>td.lastColumn {
    padding-left: 0px;
    padding-right: $mainSpacing;
    width: unset;
}

.ant-table-footer {
    background: $mainBackgroundColor;
    padding: $mainSpacing 0;
}

.ant-pagination-item {
    background: $mainBackgroundColor;
    border: none;
    color: $mainBlue;
    font-weight: bold;
    font-size: 20px;
}

.ant-table-container .ant-table-content table .ant-table-tbody .ant-table-cell {
    height: 125px;
    padding-top: $mainSpacing;
    padding-bottom: $mainSpacing;
    max-width: fit-content;

    &.noHeight {
        height: unset;
    }
}

.ant-table-tbody>tr.ant-table-row:hover>td {
    background-color: transparent;
}

.selectVenueTable {
    thead th {

            &:first-child {
                width: 4.26%;
            }
            &:nth-child(3) {
                width: 25%;
            }
        //     &:nth-child(5) {
        //         width: 37.56%;
        //     }
        &:nth-child(11) {
            div {
                text-align: left;
            }
        }
    }

    .ant-table-row td {
        &:first-child {
            width: 4.26%;
        }

        &:nth-child(3) {
            width: 25%;
        }

        &:nth-child(5) {
            width: 13.71%;
        }

        &:nth-child(7) {
            width: 7.33%;
        }

        &:nth-child(9) {
            width: 7.33%;
        }

        &:nth-child(11) {
            width: 15.3%;
        }
    }
}
.selectEventTable {
    thead th {

            &:first-child {
                width: 4.26%;
            }
            &:nth-child(3) {
                width: 25%;
            }
        //     &:nth-child(5) {
        //         width: 37.56%;
        //     }
        &:nth-child(9) {
            div {
                text-align: left;
            }
        }
    }

    .ant-table-row td {
        &:first-child {
            width: 4.26%;
        }

        &:nth-child(3) {
            width: 25%;
        }

        &:nth-child(5) {
            width: 13.71%;
        }

        &:nth-child(7) {
            width: 7.33%;
        }

        &:nth-child(9) {
            width: 16.44%;
        }
    }
}

.dashboardTable {
    margin-bottom: $mainSpacing;

    .ant-table-container .ant-table-content table {
        .ant-table-thead {
            background: transparent;

            & tr {
                & th {
                    &.secondaryHeader {
                        background: #fff;
                    }

                    background: $grayLight;
                    font-size: 12px;
                    font-weight: 700;
                    letter-spacing: 0.01em;
                    text-transform: capitalize;
                    padding-top: 14px;
                    padding-bottom: 14px;
                    color: #242A38;

                    &::before {
                        display: none;
                    }
                }
            }

            tr:last-child th:last-child {
                text-align: end;

                &.noAlign {
                    text-align: unset;
                }
            }
        }

        .ant-table-tbody {
            background: #fff;

            tr {
                vertical-align: inherit;
            }

            .ant-table-cell {
                padding-top: $smallSpacing;
                padding-bottom: $smallSpacing;
                position: relative;
                height: unset;
                min-height: unset;
            }
        }
    }

    .ant-table-tbody>tr>td {
        border-bottom: solid 2px $mainBackgroundColor;
    }
}

.logTable {
    .ant-table-container .ant-table-content table .ant-table-thead {
        background: transparent;

        & tr:first-child th {
            &:first-child {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                text-align: start;
                padding-left: $mainSpacing;
                font-size: 16px;
                line-height: 1;
            }
        }
    }

    .firstColumn {
        width: 14.14%;
    }
}

.ant-table-placeholder {
    display: none;
}
