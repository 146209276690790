.ant-form {
    color: #000000;
}

.ant-upload {
    font-size: 12px;
}

.ant-input,
.ant-input-password {
    display: flex;
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid $mainGray;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 12px;
    font-feature-settings: normal !important;
    font-variant-numeric: normal !important;
}

.ant-input-password {
    padding-top: 0;
    padding-bottom: 0;
}

.ant-input-password .ant-input {
    height: 40px;
}

.ant-form-item-control-input {
    min-height: unset;
}

.ant-form-item-control-input {
    flex: 1;
}

.ant-input:hover {
    border-color: $mainGreen;
}

.ant-input {
    border-color: $inputGray;
}

.ant-input::placeholder {
    color: $inputGray;
}

.ant-input:focus,
.ant-input-focused {
    box-sizing: border-box;
    border: solid 2px $mainGreen;
    border-right-width: 2px !important;
    box-shadow: unset;
    padding-left: 10px;
}

.ant-form-item-has-error {
    .inputWrapper {
        border-color: #ff4d4f !important;
        border-width: 2px !important;

        .placeHolder {
            padding: 12px 12px 6px;

            // &.replaced {
            //     padding: 6px 11px 7px;
            // }
        }

        input {
            bottom: 6px;
            padding-left: 11px;
        }

        &.focused {
            //   box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
            box-shadow: none
        }
    }
}