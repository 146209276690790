#filterRowSpacingDiv {
    width: 6%;

    @media (max-width: 1235px)
    {
        width: 0px;
    }
}

// .matchesFilterFirstRowButtons {
//     @media (min-width: 1224px ) and ( max-width: 1318px)
//     {
//         margin-bottom: $mainSpacing;
//     }
// }

#wrapper {
     &.toggled {
        #filterRowSpacingDiv {
            @media (max-width: 1479px) 
            {
                width: 0px;
            }
        }

        // .matchesFilterFirstRowButtons {
        //     @media (min-width: 1468px ) and ( max-width: 1562px)
        //     {
        //         margin-bottom: $mainSpacing;
        //     }
        // }
    }
}

.ant-select-clear {
    margin-top: -8px;
}