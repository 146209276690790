.ant-tooltip-inner {
	padding: 15px 13px;
	font-weight: bold;
	font-size: 12px;
	line-height: 14px;
	color: $mainFontColor;
	background: #38C9BA;
	border-radius: 6px;
	margin: 0 0 0 -18px;
}

.ant-tooltip-arrow {
	width: 22.071068px;
	height: 14.071068px;
	margin: 0 0 0 -18px;
}

.ant-tooltip-arrow-content {
	width: 12px;
	height: 12px;
	background: #38C9BA;
}


#wrapper.toggled  .menuItemTooltip{
	display:none;
}

.cellTooltip {
  letter-spacing: 0.01em;
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 12px;
  
}