/* Vars:
  ========================================================================== */

// Font
$mainFont : Ubuntu,
Helvetica,
Arial,
sans-serif;

// Size
$mainFontSize : 12px;
$largeFontSize : 16px;
$mainFontWeight : 400;
$mainLineHeight : 1.4;

$desktopWidth : 1280px;
$smDesktopWidth : 1024px;
$tableWidth : 768px;
$smTableWidth : 640px;
$phoneWidth : 480px;
$smPhoneWidth : 320px;
$mainPagetWidth : 1174px;

// Colors
$grayLight: #EBEBEB;
$mainGray: #D1D1D1;
$inputGray: #979797;
$tabTitleGray: #636971;
$header-bg: #242A38;
$mainFontColor: #242A38;
$mainRed: #EB5757;
$mainGreen: #38C9BA;
$mainBlue: #3B7B9C;
$lightBlue: #C1DFDC;
$mainYellow: #FFEE95;
$mainBackgroundColor: #F7F7F7;
$scrollThumbNailGray: #C4C4C4;

// Spacing
$mainSpacing: 20px;
$smallSpacing: 10px;
$extraLargeSpacing: 40px;
$largeSpacing: 30px;