.ant-input-search {
	.ant-input-group{
		display: flex;
		flex-direction: row-reverse;
		align-items: center;

		.ant-input {
			height: 40px;
			border: 1px solid #979797;
			background: #FFFFFF;
			border-radius: 4px;
			//border-top-right-radius: 4px;
			//border-bottom-right-radius:4px;
			//border-left: none;
			padding-left: 34px;
		}

		.ant-input-group-addon {
			//width: auto;
			left: unset !important;
			background: transparent;

			border: 1px solid #979797;
			z-index: 1;

			.ant-btn.ant-input-search-button {
				height: 28px;
				//border-radius: 0;
				//border-top-left-radius: 4px;
				//border-bottom-left-radius:4px;
				//border: 1px solid #979797;
				background: transparent;
				border: none;
				border-right: none;
			}
		}
	}
}

.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary), .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
	border-left-color: inherit;
}


